import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import { forwardRef, useState } from 'react';
import Button from '../common/Button';
import { ButtonDialog } from '../common/ButtonDialog';
import { EditDialogView } from '../common/EditDialogView';
import { PageHeader } from '../common/PageHeader';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export default function FicharConRevisionButton({ errorFichaje, errorCoordenadas, fichaje, puedeEntrar, onFichar }) {
    const [open, setOpen] = useState(false);

    const solicitudRevision = fichaje.solicitud_revision;

    const handleClose = () => {
        setOpen(false);
    };

    const titulo = errorCoordenadas ? errorCoordenadas.titulo : errorFichaje.titulo;
    const mensaje = errorCoordenadas
        ? 'Asegúrate de tener activos los servicios de ubicación en tu dispositivo móvil. En caso contrario, el fichaje deberá ser validado por administración.'
        : errorFichaje.mensaje;

    return (
        <>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <EditDialogView
                    header={<PageHeader title='Motivo de revisión' />}
                    title='Escribe el motivo de la modificación'
                    placeholder='Ejemplo: Me he olvidado de fichar la salida 18:05'
                    onClose={handleClose}
                    onSave={(motivo) => {
                        if (motivo && motivo !== solicitudRevision?.motivo) {
                            onFichar(motivo);
                        }

                        handleClose();
                    }}
                    currentText={solicitudRevision ? solicitudRevision.motivo : ''}
                />
            </Dialog>
            <ButtonDialog
                button={
                    <Button color='error' rounded fullWidth>
                        {`Solicitar ${puedeEntrar ? 'entrada' : 'salida'} fuera de ${
                            errorCoordenadas ? 'rango' : 'horario'
                        }`}
                    </Button>
                }
                onAccept={() => setOpen(true)}
                title={titulo}
                content={mensaje}
                okText='Continuar'
                okColor='success'
            />
        </>
    );
}

FicharConRevisionButton.propTypes = {
    fichaje: PropTypes.object,
    errorFichaje: PropTypes.object,
    errorCoordenadas: PropTypes.object,
    puedeEntrar: PropTypes.bool,
    onFichar: PropTypes.func,
};
