import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { tareasProvider } from '../../api';
import { format, formatISODate } from '../../utils';
import SlidingDialog from '../common/SlidingDialog';
import MonthDaySelector, { calendarColors, calendarLegend, useClassesByFechaJornada } from './MonthDaySelector';

const useStyles = makeStyles(
    (theme) => ({
        calendarWrapper: {
            margin: theme.spacing(0, 1),
        },
        leyenda: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
            margin: theme.spacing(2),
        },
        leyendaItem: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
            '&>div': {
                borderRadius: 4,
                width: 20,
                height: 20,
            },
            '& .MuiTypography-root': {
                fontWeight: 400,
            },
        },
    }),
    { name: 'MonthDaySelectorDialog' },
);

export default function MonthDaySelectorDialog({ open, onClose, fecha, onDateChange }) {
    const classes = useStyles();
    const [resumen, setResumen] = useState([]);
    const [currentDate, setCurrentDate] = useState(fecha);
    useEffect(() => {
        setCurrentDate(fecha);
    }, [fecha]);

    const year = format(currentDate, 'y');
    const month = format(currentDate, 'M');
    const fetchUrl = `jornada-laboral-operario?year=${year}&month=${month}&solo_resumen=True`;

    useEffect(() => {
        if (!open) return;

        tareasProvider.getAll(fetchUrl).then((resultado) => {
            setResumen(resultado.resumen);
        });
    }, [open, fetchUrl]);

    const getClassesByFechaJornada = useClassesByFechaJornada();

    const classesByFecha = useMemo(() => getClassesByFechaJornada(resumen), [getClassesByFechaJornada, resumen]);

    return (
        <SlidingDialog open={open} onClose={onClose} title='Calendario' removePadding>
            <div className={classes.calendarWrapper}>
                <MonthDaySelector
                    classesByFecha={classesByFecha}
                    showMonthSelector
                    fecha={fecha}
                    onDateChange={(ev) => {
                        onDateChange(formatISODate(ev));
                        onClose();
                    }}
                    onMonthChange={setCurrentDate}
                />
            </div>
            <div className={classes.leyenda}>
                {Object.entries(calendarColors).map(([key, color]) => (
                    <div className={classes.leyendaItem} key={key}>
                        <div style={{ backgroundColor: color }} />
                        <Typography variant='body2'>{calendarLegend[key]}</Typography>
                    </div>
                ))}
            </div>
        </SlidingDialog>
    );
}

MonthDaySelectorDialog.propTypes = {
    fecha: PropTypes.any,
    onClose: PropTypes.any,
    onDateChange: PropTypes.any,
    open: PropTypes.any,
};
