import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { isFuture } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fichajesProvider, operariosProvider } from '../../../api';
import useAuthState from '../../../contexts/AuthState';
import useSplash from '../../../contexts/SplashState';
import { format, formatISODate } from '../../../utils';
import Button from '../../common/Button';
import DrawerMenu from '../../common/DrawerMenu';
import { PageBody } from '../../common/PageBody';
import { PageBodySection } from '../../common/PageBodySection';
import { PageHeader } from '../../common/PageHeader';
import { BaseFirmarView } from '../../tareas/firmar/FirmarView';
import MonthDaySelector from '../../tareas/MonthDaySelector';
import FichajeDialog from './FichajeDialog';

const useStyles = makeStyles(
    (theme) => ({
        header: {
            paddingBottom: 0,
        },
        headerContent: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
        },
        body: {
            // padding: 16,
        },
        calendarWrapper: {
            margin: theme.spacing(0, 1),
        },
        leyenda: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
            margin: theme.spacing(2),
        },
        leyendaItem: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
            color: 'white',
            '&>div': {
                borderRadius: 4,
                width: 20,
                height: 20,
            },
            '& .MuiTypography-root': {
                fontWeight: 400,
            },
        },
        firmaContainer: {
            background: 'white',
            paddingBottom: theme.spacing(2),
            marginTop: 'auto',
        },
        signature: {
            border: '1px dashed #bababa',
            borderRadius: 4,
            maxHeight: 200,
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
            '& img': {
                margin: theme.spacing(2),
                objectFit: 'cover',
                overflow: 'hidden',
            },
        },
        signatureName: {
            fontSize: 12,
            color: '#818cae',
            marginBottom: theme.spacing(1),
        },
    }),
    { name: 'HistorialFichajes' },
);

const calendarColors = {
    day: '#163E88',
    noLaborable: '#4C60FE',
    pendiente: '#E94F4F',
    revisado: '#F3AC3D',
    verificado: '#30BDA1',
};

const calendarLegend = {
    day: 'Laborable, sin registro',
    noLaborable: 'No laborable',
    pendiente: 'Modificación solicitada',
    revisado: 'Falta la aprobación de administración',
    verificado: 'Fichaje aprobado',
};

const useFichajeStyles = makeStyles(
    (theme) => ({
        noLaborable: {
            '& button': {
                backgroundColor: `${calendarColors.noLaborable} !important`,
            },
        },
        pendiente: {
            '& button': {
                backgroundColor: `${calendarColors.pendiente} !important`,
            },
        },
        revisado: {
            '& button': {
                backgroundColor: `${calendarColors.revisado} !important`,
            },
        },
        verificado: {
            '& button': {
                backgroundColor: `${calendarColors.verificado} !important`,
            },
        },
    }),
    { name: 'Fichaje' },
);

export function getClassNameFromFichaje(fecha, estado, noLaborable) {
    if (!isFuture(new Date(fecha)) && estado) {
        return estado.toLowerCase();
    }
    return noLaborable ? 'noLaborable' : null;
}

export function HistorialFichajes() {
    const history = useHistory();
    const classes = useStyles();
    const fichajeClasses = useFichajeStyles();
    const [fichajes, setFichajes] = useState([]);
    const [noLaborables, setNoLaborables] = useState([]);
    const [fecha, setFecha] = useState(new Date());
    const [selectedFichaje, setSelectedFichaje] = useState(null);
    const year = format(fecha, 'y');
    const month = format(fecha, 'M');
    const [firma, setFirma] = useState(null);
    const { showCustomComponent } = useSplash();
    const {
        userInfo: { name: userName },
    } = useAuthState();

    useEffect(() => {
        setFirma(null);
        fichajesProvider.getFichajesMonth(year, month).then(setFichajes);
    }, [year, month]);

    useEffect(() => {
        operariosProvider.getNoLaborablesYear(year).then(setNoLaborables);
    }, [year]);

    const classesByFecha = useMemo(() => {
        const fichajesEstado = Object.fromEntries(
            fichajes.map(({ fecha, admin_verificado: administracion, operario_verificado: operario }) => {
                let estado = null;
                if (!operario) {
                    estado = 'pendiente';
                } else if (!administracion) {
                    estado = 'revisado';
                } else {
                    estado = 'verificado';
                }
                return [fecha, estado.toLowerCase()];
            }),
        );

        const days = [];
        const date = new Date(year, month - 1, 1);
        while (date.getMonth() === month - 1) {
            const currentDay = formatISODate(date);
            const className = getClassNameFromFichaje(
                currentDay,
                fichajesEstado[currentDay],
                noLaborables.includes(currentDay),
            );

            days.push([currentDay, className ? fichajeClasses[className] : null]);
            date.setDate(date.getDate() + 1);
        }

        return Object.fromEntries(days);
    }, [fichajes, noLaborables]);

    function onUpdated(fichajesVerificados) {
        setFichajes((fichajes) => {
            const newFichajes = fichajes.map((f) => {
                const fichajeVerificado = fichajesVerificados.find((fv) => fv.id === f.id);
                if (!fichajeVerificado) return f;

                return {
                    ...f,
                    ...fichajeVerificado,
                };
            });
            const existingFichajeIds = fichajes.map((f) => f.id);
            const addedFichajes = fichajesVerificados.filter((f) => !existingFichajeIds.includes(f.id));
            if (addedFichajes.length > 0) {
                newFichajes.push(...addedFichajes);
            }
            return newFichajes;
        });
    }

    const mesBloqueado = fichajes.length > 0 && fichajes.every((f) => f.bloqueado);
    const hasFirmaExistente = fichajes.length > 0 && fichajes.every((f) => f.firma_operario_image_url);

    return (
        <React.Fragment>
            <PageHeader
                startButton={
                    <IconButton onClick={() => history.push('/fichajes')}>
                        <ArrowBackIcon />
                    </IconButton>
                }
                title='Historial de fichajes'
                endButton={
                    <>
                        <DrawerMenu />
                    </>
                }
                className={classes.header}
            />
            <PageBody className={classes.body} bodyGap={8} color='dark' paddingTop={0}>
                <div className={classes.headerContent}>
                    <MonthDaySelector
                        showMonthSelector
                        showSelectedDay={false}
                        fecha={fecha}
                        classesByFecha={classesByFecha}
                        onDateChange={(date) => {
                            setFecha(date);

                            const fichaje = fichajes.find((f) => f.fecha === formatISODate(date));
                            if (fichaje) {
                                setSelectedFichaje(fichaje);
                            } else if (!isFuture(date)) {
                                setSelectedFichaje({
                                    fecha: date,
                                    marcajes: [],
                                });
                            }
                        }}
                        onMonthChange={setFecha}
                    />
                    <div className={classes.leyenda}>
                        {Object.entries(calendarColors).map(([key, color]) => (
                            <div className={classes.leyendaItem} key={key}>
                                <div style={{ backgroundColor: color }} />
                                <Typography variant='body2'>{calendarLegend[key]}</Typography>
                            </div>
                        ))}
                    </div>
                </div>
                {(firma || hasFirmaExistente) && (
                    <PageBodySection className={classes.firmaContainer}>
                        <Typography variant='h4'>Firma del operario</Typography>
                        <div className={classes.signature}>
                            <img src={hasFirmaExistente ? fichajes[0].firma_operario_image_url : firma.imagen} />
                        </div>

                        {!hasFirmaExistente && (
                            <Button
                                color='primaryFilled'
                                rounded
                                fullWidth
                                style={{ marginTop: 'auto' }}
                                onClick={() => {
                                    fichajesProvider.firmarMes(year, month, firma).then(() => {
                                        setFirma(null);
                                        fichajesProvider.getFichajesMonth(year, month).then(setFichajes);
                                    });
                                }}
                            >
                                Guardar firma
                            </Button>
                        )}
                    </PageBodySection>
                )}
                {mesBloqueado && !firma && !hasFirmaExistente && (
                    <Button
                        color='primaryFilled'
                        rounded
                        style={{ margin: 16, marginTop: 'auto' }}
                        onClick={() => {
                            showCustomComponent(({ closeSplash }) => (
                                <BaseFirmarView
                                    defaultNombre={userName}
                                    nombreDisabled
                                    onClose={closeSplash}
                                    onSave={(firma) => {
                                        setFirma(firma);
                                        closeSplash();
                                    }}
                                />
                            ));
                        }}
                    >
                        Firmar hoja de horas
                    </Button>
                )}
            </PageBody>
            <FichajeDialog
                open={Boolean(selectedFichaje)}
                onClose={() => setSelectedFichaje(null)}
                fichaje={selectedFichaje}
                onUpdated={(fichajesVerificados) => {
                    onUpdated(fichajesVerificados);
                    setSelectedFichaje(null);
                }}
            />
        </React.Fragment>
    );
}
