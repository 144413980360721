import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ENVIRONMENT, IS_LOCALHOST } from './api/api-config';
import App from './App';
import './index.css';

if (!IS_LOCALHOST) {
    Sentry.init({
        dsn: 'https://8c9cd0d23a6af2b802268aaa4e9bed35@o4505630789074944.ingest.sentry.io/4505631414878208',
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ['localhost', 'api.labory.app', 'staging.api.labory.app'],
            }),
            new Sentry.Replay({
                maskAllText: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: ENVIRONMENT === 'development' ? 1.0 : 0.1, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        environment: ENVIRONMENT,
        release: process.env.REACT_APP_VERSION,
    });
}

ReactDOM.render(
    <Router>
        <App />
    </Router>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorkerRegistration.register();
