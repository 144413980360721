import { formatISODate } from '../utils';
import DataProvider from './DataProvider';

export const empty = {
    fecha_creacion: '',
    operario: null,
    operario_id: null,
    tipo: '',
    fecha_inicio: '',
    fecha_fin: '',
    motivo: '',
    minutos: '',
    minutos_dia: '',
};

export const tipoLabels = {
    EXTRAS: 'Horas extras',
    DESCANSO: 'Descanso retribuido',
    HORARIO_EXCEPCIONAL: 'Horario excepcional',
};

export const tipoOptions = Object.entries(tipoLabels).map(([value, label]) => ({ value, label }));

class RegularizacionesHorasProvider extends DataProvider {
    constructor() {
        super('regularizaciones_horas', empty);
    }

    getActivas = (fecha) => {
        return this.getAll(`activas/${formatISODate(fecha)}`);
    };
}

export const dataProvider = new RegularizacionesHorasProvider();
