import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AlarmIcon from '@material-ui/icons/Alarm';
import EditIcon from '@material-ui/icons/Edit';
import LocationOffIcon from '@material-ui/icons/LocationOff';
import classnames from 'classnames';
import { addMinutes, startOfDay } from 'date-fns';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { fichajesProvider, operariosProvider, regularizacionesHorasProvider } from '../../../api';
import { getTiempoTotalMarcajes } from '../../../api/fichajes';
import { createTiempo, getMinutos } from '../../../api/tareas-functions';
import { usePreferencias } from '../../../contexts/AuthState';
import useSplash from '../../../contexts/SplashState';
import { formatFullDateTime, formatISODate, formatNumber, formatTiempo, formatTime } from '../../../utils';
import Button from '../../common/Button';
import { ButtonDialog } from '../../common/ButtonDialog';
import NotaDia from '../../control_jornada/NotaDia';
import EntradaIcon from '../../icons/Entrada';
import SalidaIcon from '../../icons/Salida';
import { BaseEditTime } from '../../tareas/EditTime';
import FicharConRevisionButton from '../FicharConRevisionButton';
import SolicitarRevisionButton from '../SolicitarRevisionButton';
import Marcaje from './Marcaje';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(1),
            flex: 1,
        },
        loading: {
            color: 'white',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: `${theme.spacing(3)}px`,
            '& .MuiCircularProgress-root': {
                color: 'white',
            },
        },
        totalHoy: {
            color: 'white',
            fontSize: 16,
            padding: theme.spacing(1.25, 2),
            borderRadius: 24,
            lineHeight: '20px',
            marginBottom: theme.spacing(2),
            alignSelf: 'center',
            '&.success': {
                backgroundColor: theme.palette.success.main,
            },
            '&.warning': {
                backgroundColor: theme.palette.warning.main,
            },
            '&.error': {
                backgroundColor: theme.palette.error.main,
            },
        },
        lista: {
            marginBottom: theme.spacing(2),
            width: '100%',
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
        },
        dialogContentText: {
            fontSize: 16,
            color: '#213061',
            '& div': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            '& div + div': {
                marginTop: theme.spacing(1.5),
            },
        },
        buttons: {
            marginTop: 'auto',
            padding: theme.spacing(0, 2, 2, 2),
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
        },
        regularizaciones: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
            margin: theme.spacing(0, 2, 2, 2),
        },
        regularizacion: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(1)}px`,
            color: 'white',
            borderRadius: 8,
            height: 36,
            padding: theme.spacing(0, 1),
            '& .MuiTypography-root': {
                fontWeight: 400,
            },
            '& .MuiSvgIcon-root': {
                fontSize: 18,
            },
            background: `${theme.palette.warning.main}99`,
        },
    }),
    { name: 'ListaFichajesHoy' },
);

function calcularDistancia(coord1, coord2) {
    const R = 6371000; // Radio de la Tierra en metros
    const rad = Math.PI / 180; // Factor para convertir grados a radianes
    const dLat = (coord2.latitude - coord1.latitude) * rad;
    const dLon = (coord2.longitude - coord1.longitude) * rad;
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(coord1.latitude * rad) * Math.cos(coord2.latitude * rad) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distancia en metros
}

const FUERA_RANGO = 'FUERA_RANGO';
const SIN_PERMISOS = 'SIN_PERMISOS';

export default function ListaFichajesHoy({ style, className }) {
    const classes = useStyles();
    const [fichaje, setFichaje] = useState(null);
    const [regularizaciones, setRegularizaciones] = useState([]);
    const [coordenadas, setCoordenadas] = useState(null);
    const [errorCoordenadas, setErrorCoordenadas] = useState(null);
    const [direcciones, setDirecciones] = useState([]);

    const [metrosGeofichajes, usarGeofichajes] = usePreferencias('metros_geofichajes', 'usar_geofichajes');

    const { showCustomComponent } = useSplash();
    const snackbar = useSnackbar();
    const [errorFichaje, setErrorFichaje] = useState(null);

    const fetchMarcajes = useCallback(() => fichajesProvider.getMarcajesHoy().then(setFichaje), []);

    const marcajes = fichaje ? fichaje.marcajes : null;
    const marcajePausa = fichaje ? fichaje.marcaje_pausa : null;
    const minutosHorario = fichaje ? fichaje.horario : 0;

    function setSinPermisosCoordenadasError() {
        setErrorCoordenadas({
            titulo: 'Ubicación no disponible',
            mensaje:
                'No se ha podido obtener tu ubicación. Asegúrate de tener activada la localización en tu dispositivo.',
            icon: LocationOffIcon,
            tipo: SIN_PERMISOS,
        });
    }

    useEffect(() => {
        regularizacionesHorasProvider.getActivas(formatISODate(new Date())).then(setRegularizaciones);
        operariosProvider.getDirecciones().then((direcciones) =>
            setDirecciones(
                direcciones.map((direccion) => {
                    const coordsParts = direccion.coords.split(',');
                    return {
                        ...direccion,
                        coords: {
                            latitude: parseFloat(coordsParts[0]),
                            longitude: parseFloat(coordsParts[1]),
                        },
                    };
                }),
            ),
        );
        fetchMarcajes();
    }, []);

    useEffect(() => {
        if (navigator.geolocation) {
            const watchId = navigator.geolocation.watchPosition(
                function ({ coords: { latitude, longitude, accuracy } }) {
                    setCoordenadas({ latitude, longitude, accuracy });
                    setErrorCoordenadas(null);
                },
                function () {
                    setCoordenadas(null);
                    if (usarGeofichajes) setSinPermisosCoordenadasError();
                },
                {
                    enableHighAccuracy: true,
                },
            );

            return () => navigator.geolocation.clearWatch(watchId);
        }
    }, [usarGeofichajes]);

    const tiempoHoy = useMemo(() => (marcajes ? getTiempoTotalMarcajes(marcajes) : 0), [marcajes]);
    const minutosHoy = getMinutos(tiempoHoy);
    const tiempoHoyClass = minutosHoy === 0 ? 'error' : minutosHoy >= minutosHorario ? 'success' : 'warning';

    const puedeEntrar = marcajes && (marcajes.length === 0 || marcajes[marcajes.length - 1].hora_salida !== null);

    const ficharEntrada = (motivo) =>
        fichajesProvider
            .ficharEntrada(coordenadas, motivo)
            .then(fetchMarcajes)
            .catch((err) => snackbar.showMessage(err.message));
    const ficharSalida = (motivo) =>
        fichajesProvider
            .ficharSalida(coordenadas, motivo)
            .then(fetchMarcajes)
            .catch((err) => snackbar.showMessage(err.message));

    const minutesSinceStartOfDay = differenceInMinutes(new Date(), startOfDay(new Date()));
    const tiempoNow = createTiempo(minutesSinceStartOfDay);

    const canRegistrarDescanso =
        marcajes &&
        marcajes.length > 0 &&
        marcajes[marcajes.length - 1].hora_salida === null &&
        (!marcajePausa || !marcajePausa.hora_salida);

    // fichaje.horarios es un [{entrada: date, salida: date}, ...]
    // hay que comprobar si le toca fichar entrada o salida, y luego comprobar si está dentro del horario
    // si no está dentro del horario, hay que mostrar un mensaje que esta fuera del horario
    const horarioCheckKey = puedeEntrar ? 'entrada' : 'salida';

    const now = new Date();

    useEffect(() => {
        if (!fichaje) return;

        if (!fichaje.horarios || fichaje.horarios.length === 0) {
            setErrorFichaje({
                titulo: 'Horario no establecido',
                mensaje: `No tienes un horario establecido para hoy. Si solicitas una ${horarioCheckKey}, deberá ser aprobada por administración.`,
                icon: AlarmIcon,
            });
            return;
        }

        if (fichaje.horario_flexible) return;

        const diffsInMinutes = fichaje.horarios.map((h) => Math.abs((new Date(h[horarioCheckKey]) - now) / 60000));
        const minDiff = Math.min(...diffsInMinutes);

        if (minDiff > fichaje.minutos_tolerancia) {
            const minDiffIndex = diffsInMinutes.indexOf(minDiff);
            const horario = fichaje.horarios[minDiffIndex];

            const horaFichaje = formatTime(horario[horarioCheckKey]);
            setErrorFichaje({
                titulo: 'Fuera del horario establecido',
                mensaje: `Deberias fichar a las ${horaFichaje}. Si solicitas una ${horarioCheckKey} fuera de tu horario habitual, deberá ser aprobada por administración.`,
                icon: EntradaIcon,
            });
        }
    }, [fichaje]);

    useEffect(() => {
        if (errorCoordenadas || !usarGeofichajes) return;

        if (!coordenadas) {
            setSinPermisosCoordenadasError();
            return;
        }

        if (direcciones.length === 0) return;

        const distancias = direcciones.map((direccion) => calcularDistancia(direccion.coords, coordenadas));

        const minDistancia = Math.round(Math.min(...distancias));

        if (minDistancia > metrosGeofichajes) {
            const distanciaStr = minDistancia > 1000 ? `${formatNumber(minDistancia / 1000)} km` : `${minDistancia} m`;
            setErrorCoordenadas({
                titulo: 'Ubicación fuera de rango',
                mensaje: `Estás a ${distanciaStr} de tu lugar de trabajo más próximo. Deberías estar a menos de ${metrosGeofichajes} metros.`,
                icon: LocationOffIcon,
                tipo: FUERA_RANGO,
            });
        }
    }, [usarGeofichajes, metrosGeofichajes, coordenadas, direcciones, errorCoordenadas]);

    const solicitudRevision = fichaje && fichaje.id ? fichaje.solicitud_revision : null;

    const fueraDeRango = errorCoordenadas?.tipo === FUERA_RANGO;

    return (
        <>
            <div className={classnames(className, classes.root)} style={style}>
                {marcajes === null ? (
                    <div className={classes.loading}>
                        <CircularProgress />
                        <Typography variant='h3'>Cargando fichajes</Typography>
                    </div>
                ) : (
                    <>
                        <div className={classnames(classes.totalHoy, tiempoHoyClass)}>{formatTiempo(tiempoHoy)}</div>

                        <div className={classes.regularizaciones}>
                            {regularizaciones.map((regularizacion) => (
                                <div key={regularizacion.id} className={classes.regularizacion}>
                                    <AlarmIcon />{' '}
                                    <Typography variant='subtitle2'>{regularizacion.mensaje_operario}</Typography>
                                </div>
                            ))}
                            {solicitudRevision && (
                                <NotaDia
                                    nota={{
                                        titulo: 'Solicitud de revisión pendiente',
                                        mensaje: (
                                            <>
                                                <Typography variant='subtitle2'>
                                                    Tu fichaje será revisado con el siguiente motivo:
                                                </Typography>
                                                <Typography
                                                    variant='subtitle2'
                                                    style={{ padding: 8, fontStyle: 'italic' }}
                                                >
                                                    {solicitudRevision.motivo}
                                                </Typography>
                                                <SolicitarRevisionButton
                                                    fichaje={fichaje}
                                                    onUpdate={fetchMarcajes}
                                                    ButtonProps={{ size: 'small' }}
                                                />
                                            </>
                                        ),
                                        icon: EditIcon,
                                    }}
                                    newLineOnExpanded={false}
                                />
                            )}
                            {errorCoordenadas && <NotaDia nota={errorCoordenadas} newLineOnExpanded={false} />}
                        </div>

                        <div className={classes.lista}>
                            {marcajes.length === 0 && <Marcaje marcaje={{ hora_entrada: null, hora_salida: null }} />}
                            {marcajes.map((marcaje, i) => (
                                <Marcaje key={i} marcaje={marcaje} marcajePausa={marcajePausa} />
                            ))}
                        </div>
                        <div className={classes.buttons}>
                            {errorFichaje || errorCoordenadas?.tipo === SIN_PERMISOS ? (
                                <FicharConRevisionButton
                                    errorFichaje={errorFichaje}
                                    errorCoordenadas={errorCoordenadas}
                                    fichaje={fichaje}
                                    onFichar={puedeEntrar ? ficharEntrada : ficharSalida}
                                    puedeEntrar={puedeEntrar}
                                />
                            ) : (
                                <>
                                    {puedeEntrar ? (
                                        <ButtonDialog
                                            button={
                                                <Button
                                                    color={fueraDeRango ? 'dark' : 'primaryFilled'}
                                                    rounded
                                                    fullWidth
                                                    disabled={fueraDeRango}
                                                >
                                                    Fichar entrada
                                                </Button>
                                            }
                                            onAccept={ficharEntrada}
                                            title='¿Estás seguro que quieres fichar la entrada?'
                                            content={
                                                <div className={classnames(classes.lista, classes.dialogContentText)}>
                                                    <div className='entrada'>
                                                        <EntradaIcon /> Entrada
                                                    </div>
                                                    <div>{formatFullDateTime(new Date())}</div>
                                                </div>
                                            }
                                            okText='Fichar entrada'
                                            okColor='success'
                                        />
                                    ) : (
                                        <ButtonDialog
                                            button={
                                                <Button
                                                    color={fueraDeRango ? 'dark' : 'primaryFilled'}
                                                    rounded
                                                    fullWidth
                                                    disabled={fueraDeRango}
                                                >
                                                    Fichar salida
                                                </Button>
                                            }
                                            onAccept={ficharSalida}
                                            title='¿Estás seguro que quieres fichar la salida?'
                                            content={
                                                <div className={classnames(classes.lista, classes.dialogContentText)}>
                                                    <div className='salida'>
                                                        <SalidaIcon /> Salida
                                                    </div>
                                                    <div>{formatFullDateTime(new Date())}</div>
                                                </div>
                                            }
                                            okText='Fichar salida'
                                            okColor='error'
                                        />
                                    )}
                                </>
                            )}
                            {canRegistrarDescanso && (
                                <Button
                                    color='primary'
                                    rounded
                                    outline
                                    fullWidth
                                    onClick={() => {
                                        showCustomComponent(({ closeSplash }) => (
                                            <BaseEditTime
                                                title='Selecciona el tiempo'
                                                selectedDate={new Date()}
                                                onClose={closeSplash}
                                                tiempo={tiempoNow}
                                                applyInterval={false}
                                                onAccept={(tiempo) => {
                                                    closeSplash();

                                                    const minutos = tiempo.horas * 60 + tiempo.minutos;
                                                    const fecha = addMinutes(startOfDay(new Date()), minutos);

                                                    const newMarcajePausa =
                                                        marcajePausa && marcajePausa.hora_entrada
                                                            ? {
                                                                  hora_salida: fecha,
                                                                  coords_salida: coordenadas,
                                                              }
                                                            : {
                                                                  hora_entrada: fecha,
                                                                  coords_entrada: coordenadas,
                                                              };

                                                    return fichajesProvider
                                                        .ficharPausa(newMarcajePausa)
                                                        .then(fetchMarcajes)
                                                        .catch((err) => snackbar.showMessage(err.message));
                                                }}
                                            />
                                        ));
                                    }}
                                >
                                    {marcajePausa && marcajePausa.hora_entrada
                                        ? 'Registrar fin de descanso'
                                        : 'Registrar descanso'}
                                </Button>
                            )}

                            {fichaje.id && !solicitudRevision && (
                                <SolicitarRevisionButton
                                    fichaje={fichaje}
                                    onUpdate={fetchMarcajes}
                                    ButtonProps={{
                                        color: 'primary',
                                        size: 'normal',
                                        outline: true,
                                    }}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

ListaFichajesHoy.propTypes = {
    className: PropTypes.any,
    style: PropTypes.any,
};
