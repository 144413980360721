import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { format, formatISODate } from '../../utils';
import MonthDaySelector, { useClassesByFechaJornada } from '../tareas/MonthDaySelector';
import { ControlJornada } from './ControlJornada';

export default function ControlJornadaMonth({ fecha: fechaStr }) {
    const history = useHistory();

    function changeFecha(value) {
        history.replace(`/control-jornada-month/${value}`);
    }

    const fecha = new Date(fechaStr);

    const year = format(fecha, 'y');
    const month = format(fecha, 'M');

    const fetchUrl = `jornada-laboral-operario?year=${year}&month=${month}`;

    const getClassesByFechaJornada = useClassesByFechaJornada();

    const renderFilterComponent = useCallback(
        function renderFilterComponent(resumen) {
            return (
                <MonthDaySelector
                    fecha={fecha}
                    classesByFecha={getClassesByFechaJornada(resumen)}
                    onDateChange={(date) => history.push(`/control-jornada-month/${formatISODate(date)}`)}
                />
            );
        },
        [getClassesByFechaJornada, fecha],
    );

    return (
        <ControlJornada
            title={format(fecha, 'MMMM yyyy')}
            fetchUrl={fetchUrl}
            onDateChange={changeFecha}
            fecha={fechaStr}
            renderFilterComponent={renderFilterComponent}
        />
    );
}

ControlJornadaMonth.propTypes = {
    fecha: PropTypes.any,
};
