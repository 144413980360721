import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PropTypes from 'prop-types';
import { format } from '../../../utils';

const useStyles = makeStyles(
    (theme) => ({
        marcajeWrapper: {
            padding: theme.spacing(2, 0),
            display: 'flex',
            flexDirection: 'column',
            borderTop: '1px solid rgba(255, 255, 255, 0.5)',
            '&:last-of-type': {
                borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
            },
        },
        marcaje: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 2),
            '&>div': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                flex: 1,
            },
            '& .MuiTypography-body1': {
                fontSize: 14,
                fontWeight: 400,
            },
            '& .MuiTypography-body2': {
                fontSize: 32,
                fontWeight: 400,
            },
            '& .MuiSvgIcon-root': {
                fontSize: 32,
            },
        },
        marcajePausa: {
            textAlign: 'center',
        },
    }),
    { name: 'ListaFichajesHoy' },
);

export default function Marcaje({ marcaje, marcajePausa }) {
    const classes = useStyles();

    const pausaInside =
        marcajePausa &&
        marcajePausa.hora_entrada >= marcaje.hora_entrada &&
        (!marcaje.hora_salida || marcajePausa.hora_salida <= marcaje.hora_salida);

    return (
        <div className={classes.marcajeWrapper}>
            <div className={classes.marcaje}>
                <div>
                    <Typography variant='body1'>Entrada</Typography>
                    <Typography variant='body2'>
                        {marcaje.hora_entrada ? format(marcaje.hora_entrada, 'HH:mm') : '--:--'}
                    </Typography>
                </div>

                <ArrowForwardIcon />

                <div>
                    <Typography variant='body1'>Salida</Typography>
                    <Typography variant='body2'>
                        {marcaje.hora_salida ? format(marcaje.hora_salida, 'HH:mm') : '--:--'}
                    </Typography>
                </div>
            </div>
            {pausaInside && marcajePausa.hora_entrada && (
                <Typography variant='body1' className={classes.marcajePausa}>
                    {marcajePausa.hora_salida
                        ? `Descanso registrado de ${format(marcajePausa.hora_entrada, 'HH:mm')} a ${format(
                              marcajePausa.hora_salida,
                              'HH:mm',
                          )}`
                        : `Descanso iniciado a las ${format(marcajePausa.hora_entrada, 'HH:mm')}`}
                </Typography>
            )}
        </div>
    );
}

Marcaje.propTypes = {
    marcaje: PropTypes.any,
    marcajePausa: PropTypes.any,
};
