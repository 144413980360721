import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import isToday from 'date-fns/isToday';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { createTiempo } from '../../api/tareas-functions';
import { format, formatFullDate, formatTiempo } from '../../utils';
import { calendarColors, getClassNameFromResumenJornada } from '../tareas/MonthDaySelector';
import { ControlJornada } from './ControlJornada';

const useStyles = makeStyles(
    (theme) => ({
        dayInfo: {
            borderRadius: 18,
            height: 36,
            marginTop: theme.spacing(1),
            padding: theme.spacing(0, 2),
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            textTransform: 'capitalize',
        },
    }),
    { name: 'ControlJornadaDay' },
);

export default function ControlJornadaDay({ fecha: fechaStr }) {
    const history = useHistory();
    const classes = useStyles();

    function changeFecha(value) {
        history.replace(`/control-jornada-day/${value}`);
    }

    const fecha = new Date(fechaStr);

    const year = format(fecha, 'y');
    const month = format(fecha, 'M');

    const fetchUrl = `jornada-laboral-operario?year=${year}&month=${month}`;

    function renderFilterComponent(resumen) {
        const resumenFecha = resumen.find((r) => r.fecha === fechaStr);
        if (!resumenFecha) return null;

        const {
            jornada,
            laborables: { horario, efectivas },
        } = resumenFecha;

        const className = getClassNameFromResumenJornada(horario, efectivas, fecha, jornada) ?? 'day';

        return (
            <div className={classes.dayInfo} style={{ backgroundColor: calendarColors[className] }}>
                <Typography variant='body2'>
                    {isToday(fecha) ? 'Hoy: ' : ''}
                    {formatFullDate(fecha)}
                </Typography>
                <Typography variant='body2'>{formatTiempo(createTiempo(jornada))}</Typography>
            </div>
        );
    }

    return (
        <ControlJornada
            title={format(fecha, 'MMMM yyyy')}
            fetchUrl={fetchUrl}
            onDateChange={changeFecha}
            fecha={fechaStr}
            renderFilterComponent={renderFilterComponent}
            aggregateTodayOnly
        />
    );
}

ControlJornadaDay.propTypes = {
    fecha: PropTypes.any,
};
